// @flow
import React, { Component } from 'react';
// Components
import {
  FormButton,
  InputFieldErrorMessage,
  validationHelpers,
  cityDropDownHelper,
  Checkbox,
} from '../../../common';
// Types
import type { Address } from '../../../types';
// Analytics
import * as Analytics from '../../../common/analytics';
// Style
import './MyAccountAddressEditor.css';

type Props = {
  address: ?Address,
  loading: boolean,
  onSubmitAddress: (address: Address) => void,
};

type State = {
  receiverName: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  zipcode: string,
  neighborhood: string,
  telephone: string,
  pointOfReference: string,
  cnpj: string,
  ieCompany: string,
  company: string,
  isCompanyAddress: boolean,

  receiverNameError: string,
  addressLine1Error: string,
  addressLine2Error: string,
  cityError: string,
  zipcodeError: string,
  neighborhoodError: string,
  telephoneError: string,
  pointOfReferenceError: string,
  cnpjError: string,
  ieCompanyError: string,
  companyError: string,
};

class MyAccountAddressEditor extends Component {
  state: State;
  validateUserInput: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      receiverName: props.address ? props.address.receiverName : '',
      addressLine1: props.address ? props.address.addressLine1 : '',
      addressLine2: props.address ? props.address.addressLine2 : '',
      city: props.address ? props.address.city : cityDropDownHelper.cities[0],
      zipcode: props.address ? props.address.zipcode : '',
      neighborhood: props.address ? props.address.neighborhood : '',
      telephone: props.address ? props.address.telephone : '',
      pointOfReference: props.address ? props.address.reference : '',
      cnpj: props.address ? props.address.cnpj || '' : '',
      ieCompany: props.address ? props.address.ieCompany || '' : '',
      company: props.address ? props.address.company || '' : '',
      isCompanyAddress: props.address ? props.address.cnpj != null : false,

      receiverNameError: '',
      addressLine1Error: '',
      addressLine2Error: '',
      cityError: '',
      zipcodeError: '',
      neighborhoodError: '',
      telephoneError: '',
      pointOfReferenceError: '',
      cnpjError: '',
      ieCompanyError: '',
      companyError: '',
    };
  }

  // Function for validating the address before sending to the server
  validateUserInput = () => {
    let noValidationErrors: boolean = true;

    const receiverNameError = validationHelpers.address.name(
      this.state.receiverName,
    );
    this.setState({ receiverNameError });
    if (receiverNameError) {
      noValidationErrors = false;
    }

    const addressLine1Error = validationHelpers.address.addressLine1(
      this.state.addressLine1,
    );
    this.setState({ addressLine1Error });
    if (addressLine1Error) {
      noValidationErrors = false;
    }

    const addressLine2Error = validationHelpers.address.addressLine2(
      this.state.addressLine2,
    );
    this.setState({ addressLine2Error });
    if (addressLine2Error) {
      noValidationErrors = false;
    }

    const cityError = validationHelpers.address.city(this.state.city);
    this.setState({ cityError });
    if (cityError) {
      noValidationErrors = false;
    }

    const zipcodeError = validationHelpers.address.zipcode(this.state.zipcode);
    this.setState({ zipcodeError });
    if (zipcodeError) {
      noValidationErrors = false;
    }

    const neighborhoodError = validationHelpers.address.neighborhood(
      this.state.neighborhood,
    );
    this.setState({ neighborhoodError });
    if (neighborhoodError) {
      noValidationErrors = false;
    }

    const telephoneError = validationHelpers.address.telephone(
      this.state.telephone,
    );
    this.setState({ telephoneError });
    if (telephoneError) {
      noValidationErrors = false;
    }

    const pointOfReferenceError = validationHelpers.address.pointOfReference(
      this.state.pointOfReference,
    );
    this.setState({ pointOfReferenceError });
    if (pointOfReferenceError) {
      noValidationErrors = false;
    }

    if (this.state.isCompanyAddress) {
      const cnpjError = validationHelpers.address.cnpj(this.state.cnpj);
      this.setState({ cnpjError });
      if (cnpjError) {
        noValidationErrors = false;
      }

      const ieCompanyError = validationHelpers.address.ieCompany(
        this.state.ieCompany,
      );
      this.setState({ ieCompanyError });
      if (ieCompanyError) {
        noValidationErrors = false;
      }

      const companyError = validationHelpers.address.company(
        this.state.company,
      );
      this.setState({ companyError });
      if (companyError) {
        noValidationErrors = false;
      }
    }

    if (noValidationErrors) {
      const newAddress: UserAddress = {
        id: this.props.address ? this.props.address.id : null,
        receiverName: this.state.receiverName,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        city: this.state.city,
        zipcode: this.state.zipcode,
        neighborhood: this.state.neighborhood,
        telephone: this.state.telephone,
        reference: this.state.pointOfReference,
        cnpj: this.state.isCompanyAddress ? this.state.cnpj : null,
        ieCompany: this.state.isCompanyAddress ? this.state.ieCompany : null,
        company: this.state.isCompanyAddress ? this.state.company : null,
      };
      this.props.onSubmitAddress(newAddress);
    } else {
      console.error('Validation error on address form.');
    }
  };

  render() {
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          this.validateUserInput();
        }}
      >
        <div className="my-account-address-editor-main">
          <InputFieldErrorMessage message={this.state.receiverNameError} />
          <input
            type="text"
            className="my-account-address-editor-large-input"
            placeholder="Nome do recebedor"
            value={this.state.receiverName}
            onChange={e => this.setState({ receiverName: e.target.value })}
          />

          <InputFieldErrorMessage message={this.state.addressLine1Error} />
          <input
            type="text"
            className="my-account-address-editor-large-input"
            placeholder="Endereço, ex.: R. Altair Nogueira, 123"
            value={this.state.addressLine1}
            onChange={e => this.setState({ addressLine1: e.target.value })}
          />
          <InputFieldErrorMessage message={this.state.addressLine2Error} />
          <input
            type="text"
            className="my-account-address-editor-large-input"
            placeholder="Complemento, ex.: Bloco 3, ap 124"
            value={this.state.addressLine2}
            onChange={e => this.setState({ addressLine2: e.target.value })}
          />

          <div className="my-account-address-editor-line">
            <div className="my-account-address-editor-small-content">
              <input
                type="text"
                className="my-account-address-editor-small-input"
                placeholder="Bairro"
                value={this.state.neighborhood}
                onChange={e => this.setState({ neighborhood: e.target.value })}
              />
              <InputFieldErrorMessage message={this.state.neighborhoodError} />
            </div>

            <div className="my-account-address-editor-small-content">
              <div className="my-account-address-editor-city-dropdown-container">
                <select
                  className="my-account-address-editor-city-dropdown"
                  onChange={e => {
                    Analytics.logSelectedAddressCity(e.target.value);
                    this.setState({ city: e.target.value });
                  }}
                  defaultValue={this.state.city}
                >
                  {cityDropDownHelper.cities.map((city, i) => {
                    return (
                      <option key={i} value={city}>
                        {city}
                      </option>
                    );
                  })}
                </select>
              </div>
              <InputFieldErrorMessage message={this.state.cityError} />
            </div>

            <div className="my-account-address-editor-small-content">
              <input
                type="text"
                className="my-account-address-editor-small-input"
                placeholder="CEP (Só números)"
                value={this.state.zipcode.replace(/\D/g, '')}
                onChange={e => this.setState({ zipcode: e.target.value })}
              />
              <InputFieldErrorMessage message={this.state.zipcodeError} />
            </div>
          </div>

          <div className="my-account-address-editor-line">
            <div className="my-account-address-editor-small-content">
              <input
                type="text"
                className="my-account-address-editor-small-input"
                placeholder="Telefone com DDD"
                value={this.state.telephone.replace(/\D/g, '')}
                onChange={e => this.setState({ telephone: e.target.value })}
              />
              <InputFieldErrorMessage message={this.state.telephoneError} />
            </div>

            <div className="my-account-address-editor-medium-content">
              <input
                type="text"
                className="my-account-address-editor-medium-input"
                placeholder="Ponto de referência"
                value={this.state.pointOfReference}
                onChange={e =>
                  this.setState({ pointOfReference: e.target.value })
                }
              />
              <InputFieldErrorMessage
                message={this.state.pointOfReferenceError}
              />
            </div>
          </div>

          <Checkbox
            className="my-account-address-editor-checkbox"
            checked={this.state.isCompanyAddress}
            onChange={e =>
              this.setState({ isCompanyAddress: e.target.checked })
            }
            label={<span>Selecione para endereço comercial</span>}
          />

          {this.state.isCompanyAddress ? (
            <div className="my-account-address-editor-company">
              <InputFieldErrorMessage message={this.state.cnpjError} />
              <input
                type="text"
                className="my-account-address-editor-large-input"
                placeholder="CNPJ"
                value={this.state.cnpj}
                onChange={e => this.setState({ cnpj: e.target.value })}
              />

              <InputFieldErrorMessage message={this.state.ieCompanyError} />
              <input
                type="text"
                className="my-account-address-editor-large-input"
                placeholder="Inscrição Estadual"
                value={this.state.ieCompany}
                onChange={e => this.setState({ ieCompany: e.target.value })}
              />

              <InputFieldErrorMessage message={this.state.companyError} />
              <input
                type="text"
                className="my-account-address-editor-large-input"
                placeholder="Nome da Empresa"
                value={this.state.company}
                onChange={e => this.setState({ company: e.target.value })}
              />
            </div>
          ) : null}

          <div className="my-account-address-editor-submit-content">
            <div className="my-account-address-editor-submit-button">
              <FormButton
                text={'SALVAR'}
                icon=""
                buttonType={'form-button-tertiary'}
                loading={this.props.loading}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default MyAccountAddressEditor;
