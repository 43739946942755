import amplitude from 'amplitude-js';
// Logger
import { logException } from '../logHelper';
// Types
import type { UserAddress } from '../../../user';

// Init amplitude
amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_ANALYTICS_API_KEY);

export const setUserProperties = user => {
  try {
    amplitude.setUserId(user.id ? user.id : null);

    if (user.id) {
      amplitude.getInstance().setUserProperties({
        name: user.name,
        created_at: user.createdAt,
        is_business: user.isBusiness,
        email: user.email,
      });
    }
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logEvent = (eventName, eventProperties) => {
  try {
    amplitude.getInstance().logEvent(eventName, mergeAppInfo(eventProperties));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// PRODUCTS RELATED ////////////////////////
//////////////////////////////////////////////////////////////////

export const logProductView = (product, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent('viewed product', productProperties(product, path, viewSource));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logProductImpression = (product, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'impression product',
        productProperties(product, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

const productProperties = (product, path, viewSource) => {
  return mergeAppInfo({
    variant_id: product.id,
    product_id: product.productId,
    name: product.name,
    price: product.price,
    manufacturer: product.manufacturer,
    unit_for_quantity: product.unitForQuantity,
    total_on_hand: product.totalOnHand,
    list_price: product.listPrice,
    view_source: viewSource ? viewSource.toLocaleLowerCase() : null,
    in_stock: product.inStock,
    has_images: product.images.length > 0,
    increment_quantity: product.incrementQuantity,
    default_quantity: product.defaultQuantity,
    path: path,
  });
};

//////////////////////////////////////////////////////////////////
//////////////////////// HOMEPAGE RELATED ////////////////////////
//////////////////////////////////////////////////////////////////

export const logTappedBanner = (bannerName: String) => {
  try {
    amplitude
      .getInstance()
      .logEvent('tapped banner', mergeAppInfo({ bannerName: bannerName }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logTappedNavList = (selectedItem: String) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'tapped nav-list',
        mergeAppInfo({ selectedItem: selectedItem }),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// CHECKOUT RELATED ////////////////////////
//////////////////////////////////////////////////////////////////

export const logTappedCheckout = items => {
  try {
    amplitude
      .getInstance()
      .logEvent('tapped checkout', mergeAppInfo({ items: items }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logSelectedAddress = (address: UserAddress) => {
  try {
    amplitude
      .getInstance()
      .logEvent('selected address', mergeAppInfo({ address: address }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logEditAddress = (address: UserAddress) => {
  try {
    amplitude
      .getInstance()
      .logEvent('edited address', mergeAppInfo({ address: address }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logDeleteAddress = (deletedAddress: UserAddress) => {
  try {
    amplitude.getInstance().logEvent('deleted address', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logTappedAddNewAddress = () => {
  try {
    amplitude
      .getInstance()
      .logEvent('tapped add new address', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logSelectedAddressCity = city => {
  try {
    amplitude
      .getInstance()
      .logEvent('tapped address city dropdown', mergeAppInfo({ city: city }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logConfirmedAddress = () => {
  try {
    amplitude.getInstance().logEvent('confirmed address', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logSelectedDelivery = (
  deliveriesRemaining: Number,
  deliveryWindowStart: Date,
  deliveryWindowEnd: Date,
) => {
  try {
    amplitude.getInstance().logEvent(
      'selected delivery',
      mergeAppInfo({
        deliveriesRemaining: deliveriesRemaining,
        deliveryWindowStart: deliveryWindowStart,
        deliveryWindowEnd: deliveryWindowEnd,
      }),
    );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logConfirmedDelivery = () => {
  try {
    amplitude.getInstance().logEvent('confirmed delivery', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logAddNewCard = card => {
  try {
    amplitude
      .getInstance()
      .logEvent('added new card', mergeAppInfo({ card: card }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logDeleteCreditCard = (creditCardId: number) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'deleted credit card',
        mergeAppInfo({ creditCardId: creditCardId }),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logCompletedOrder = () => {
  try {
    amplitude.getInstance().logEvent('completed order', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
//////////////////////// BARBECUE RELATED ////////////////////////
//////////////////////////////////////////////////////////////////

export const logCreatedBarbecue = (
  amountOfAdults: Number,
  amountOfChildren: Number,
  selectedOptionValue: Number,
) => {
  try {
    amplitude.getInstance().logEvent(
      'created barbecue',
      mergeAppInfo({
        amountOfAdults: amountOfAdults,
        amountOfChildren: amountOfChildren,
        selectedOptionValue: selectedOptionValue,
      }),
    );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logAddedBarbecueToCart = () => {
  try {
    amplitude
      .getInstance()
      .logEvent('added barbecue to cart', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
///////////////////////// BLENDS RELATED /////////////////////////
//////////////////////////////////////////////////////////////////

export const logTappedCreateBlend = () => {
  try {
    amplitude.getInstance().logEvent('tapped create blend', mergeAppInfo({}));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logSavedBlend = blend => {
  try {
    amplitude
      .getInstance()
      .logEvent('saved blend blend', mergeAppInfo({ blend: blend }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logAddedBlendToCart = blendId => {
  try {
    amplitude
      .getInstance()
      .logEvent('added blend to cart', mergeAppInfo({ blendId: blendId }));
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

//////////////////////////////////////////////////////////////////
/////////////////////// LINE ITEM RELATED ///////////////////////
//////////////////////////////////////////////////////////////////

export const logLineItemView = (lineItem, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'viewed line item',
        lineItemProperties(lineItem, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logLineItemAdd = (lineItem, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'added line item',
        lineItemProperties(lineItem, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

export const logLineItemRemove = (lineItem, path, viewSource: ?string) => {
  try {
    amplitude
      .getInstance()
      .logEvent(
        'removed line item',
        lineItemProperties(lineItem, path, viewSource),
      );
  } catch (e) {
    console.error(e);
    logException(e);
  }
};

const lineItemProperties = (lineItem, path, viewSource) => {
  return mergeAppInfo({
    variant_id: lineItem.variant.id,
    product_id: lineItem.variant.productId,
    name: lineItem.variant.name,
    portioning_size: lineItem.portioningSize,
    price: lineItem.price,
    quantity: lineItem.quantity,
    total: lineItem.total,
    order_id: lineItem.orderId,
    mix_id: lineItem.mixId,
    manufacturer: lineItem.variant.manufacturer,
    unit_for_quantity: lineItem.variant.unitForQuantity,
    total_on_hand: lineItem.variant.totalOnHand,
    list_price: lineItem.variant.listPrice,
    adjustment_total: lineItem.adjustmentTotal,
    pre_tax_amount: lineItem.preTaxAmount,
    in_stock: lineItem.variant.inStock,
    view_source: viewSource ? viewSource.toLowerCase() : null,
    has_images: lineItem.variant.images.length > 0,
    increment_quantity: lineItem.variant.incrementQuantity,
    default_quantity: lineItem.variant.defaultQuantity,
    observation_present: !!lineItem.observation,
    created_at: lineItem.createdAt,
    updated_at: lineItem.updatedAt,
    path: path,
  });
};

const mergeAppInfo = (properties: Object) => {
  const appInfo = {
    app_version: process.env.REACT_APP_BUILD_COMMIT,
    app_source: 'sales_frontend',
  };

  return Object.assign({}, properties, appInfo);
};
