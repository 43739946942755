// @flow
import './MyAccountAddressAddNewButton.css';
import React, { Component } from 'react';
// Analytics
import * as Analytics from '../../../common/analytics';

type Props = {
  isEditing: boolean,
  onClick: () => void,
};

class MyAccountAddressAddNewButton extends Component {
  props: Props;
  render() {
    return (
      <div
        className="my-account-address-add-new-button-main"
        onClick={() => {
          Analytics.logTappedAddNewAddress();
          this.props.onClick();
        }}
      >
        <div className="my-account-address-add-new-button-content">
          <div className="my-account-address-add-new-text">
            Adicionar novo endereço
          </div>
          <div
            className={
              this.props.isEditing
                ? 'my-account-address-add-new-arrow-up'
                : 'my-account-address-add-new-arrow-down'
            }
          />
        </div>
      </div>
    );
  }
}

export default MyAccountAddressAddNewButton;
