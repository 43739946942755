// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Components
import Stamp from '../components/Stamp';
import paymentMethod1 from '../images/payment_method_1.png';
// Style
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <div className="footer-container">
        <div className="footer-main">
          <Link to="/">
            <Stamp />
          </Link>
          <div className="footer-sub-container">
            <Link to="/sobre/">Sobre nós</Link>
            <Link to="/trabalhe/">Trabalhe conosco</Link>
            <Link to="/termos/">Termos de uso</Link>
            {/* <Link to="/mapa-do-site">Mapa do Site</Link> */}
          </div>
          <div className="footer-sub-container">
            <Link to="/busca/">Busca</Link>
            <Link to="/departamentos/">Departamentos</Link>
            <Link to="/perguntas/">Perguntas Frequentes</Link>
            <p className="footer-version-information">
              Versão: {(process.env.REACT_APP_BUILD_COMMIT || 0).slice(0, 8)}
            </p>
          </div>
          <div className="footer-sub-container">
            <Link to="/minha-conta/informacoes/">Minha conta</Link>
            <Link to="/contato/">Entre em contato</Link>
            <img src={paymentMethod1} alt="Visa, MasterCard, Hipercard" />
            <div className="footer-social-media">
              <a
                href="https://www.facebook.com/bigdeliverybr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook-square" aria-hidden="true" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCPEm42gkJb23RUD74jiJBVA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-youtube" aria-hidden="true" />
              </a>
              <a
                href="https://www.instagram.com/bigdeliverybr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram" aria-hidden="true" />
              </a>
              <a
                href="https://twitter.com/bigdbr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-twitter" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-company-details">
          <p>
            Big Delivery Ecommerce de Alimentos LTDA | Big Delivery | CNPJ:
            40.478.970/0001-11
          </p>
          <p>
            R. Minas Gerais, 486, Loja Terreo, 41830-020 – Pituba – Salvador/BA
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
