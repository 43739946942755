// @flow
import React from 'react';
// Components
import {
  Button,
  InputFieldErrorMessage,
  ColorRow,
  validationHelpers,
} from '../../common';
// Images
import ChickenStamp from '../../search/images/chicken_magnify_glass.png';
// Styles
import './ForgotPassword.css';

type State = {
  email: string,
  // Errors
  emailError: string,
};

type Props = {
  isLoading: boolean,
  errors: {
    base?: string,
    email?: string,
  },
  onSubmitRecoverPassword: (
    password: string,
    passwordConfirmation: string,
  ) => void,
};

class PasswordRecovery extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
    };
  }

  // Validates the form on submission. If there are no validation error dispatches action.
  validateUserInput() {
    const email = this.state.email;

    let noValidationErrors: boolean = true;

    if (email === '') {
      // Email is missing
      this.setState({ emailError: 'Campo é obrigatório' });
      noValidationErrors = false;
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      // If there isn't an @ then its not an email
      this.setState({ emailError: 'Email inválido' });
      noValidationErrors = false;
    } else {
      this.setState({ emailError: '' });
    }

    if (noValidationErrors) {
      this.props.onSubmitForgotPassword(email);
    } else {
      console.error('Validation error on registration form.');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.isLoading && !nextProps.isLoading && nextProps.errors.base) {
      this.setState({
        emailError: nextProps.errors.base,
      });
    }
  }

  render() {
    return (
      <div className="forgot-password-main">
        <ColorRow color="red" text="" />

        <div className="forgot-password-box">
          <img className="forgot-password-image" src={ChickenStamp} alt="" />
          <h3>Perdi Minha Senha</h3>
          {!this.props.successMessage ? (
            <form
              onSubmit={e => {
                e.preventDefault();
                this.validateUserInput();
              }}
            >
              <InputFieldErrorMessage message={this.state.emailError} />
              <input
                type="text"
                className="login-box-input"
                placeholder="Email"
                value={this.state.email}
                onBlur={() => {
                  this.setState({
                    emailError: validationHelpers.user.email(this.state.email),
                  });
                }}
                onChange={e => this.setState({ email: e.target.value.trim() })}
              />

              <Button
                text="RESETAR SENHA"
                icon=""
                buttonType="button-primary"
                loading={this.props.isLoading}
              />
            </form>
          ) : (
            <div>{this.props.successMessage}</div>
          )}
        </div>
      </div>
    );
  }
}

export default PasswordRecovery;
