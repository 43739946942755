// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Analytics
import * as Analytics from '../../../common/analytics';
// Components
import PaneTitle from '../PaneTitle';
import AddressSummary from './AddressSummary';
import AddressPicker from './AddressPicker';
import {
  MyAccountAddressEditor as AddressEditor,
  MyAccountAddressAddNewButton as AddressAddNewButton,
} from '../../../user';
import CheckoutContinueButton from '../CheckoutContinueButton';
import CheckoutErrors from '../CheckoutErrors';
// Types
import type { UserAddress } from '../../../user';
import type { OrderAddress } from '../../types';
// Styles
import './AddressPane.css';

export type State = {
  editing: number | 'new' | false,
  address: ?UserAddress,
};

export type Props = {
  errors: string[],
  active: boolean,
  selected: ?OrderAddress,
  loading: boolean,
  onSubmit: (address: UserAddress) => void,
  dismissErrors: () => void,
  // Address Connector
  addresses: UserAddress[], // From connector
  addressesLoading: boolean, // From connector
  editingLoading: boolean, // From connector
  addingLoading: boolean, // From connector
  deletingLoading: (id: number) => boolean, // From connector
  onNew: (address: UserAddress) => void, // From connector
  onEdit: (address: UserAddress) => void, // From connector
  onDelete: (id: number) => void, // From connector
};

class AddressPane extends Component {
  props: Props;
  state: State;
  constructor(props: Props) {
    super(props);

    if (props.addresses.length > 0) {
      this.state = {
        editing: false,
        address: _.maxBy(props.addresses, 'id'),
      };
    } else {
      this.state = {
        editing: 'new',
        address: null,
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // Whenever going into this tab the newest address should be selected
    if (
      this.props.addresses.length < nextProps.addresses.length ||
      (!this.props.active && nextProps.active && nextProps.addresses.length > 0)
    ) {
      const newestAddress = _.maxBy(nextProps.addresses, 'id');
      this.setState((state: State, props: Props) => ({
        editing: false,
        address: newestAddress,
      }));
    }
  }

  newAddress() {
    this.setState((state: State, props: Props): State => ({
      ...state,
      editing: this.state.editing !== 'new' ? 'new' : false,
      address: null,
    }));
  }

  pickAddress(address: UserAddress) {
    this.setState((state: State, props: Props): State => ({
      ...state,
      editing: false,
      address,
    }));
  }

  editAddress(address: UserAddress) {
    this.setState((state: State, props: Props): State => ({
      ...state,
      editing: address.id,
      address: null,
    }));
  }

  updateAddress(address: UserAddress) {
    this.props.onEdit(address);
    this.setState((state: State, props: Props): State => ({
      ...state,
      editing: false,
      address,
    }));
  }

  deleteAddress(address: UserAddress) {
    Analytics.logDeleteAddress(address);
    this.setState((state: State, props: Props): State => ({ ...state }));

    // Dispatch the action that deletes the address
    this.props.onDelete(address.id);
  }

  submitAddress() {
    if (this.state.address) this.props.onSubmit(this.state.address);
  }

  render() {
    return (
      <div>
        <PaneTitle
          active={this.props.active}
          number={1}
          title={'Endereço de entrega'}
        >
          {!this.props.active && this.props.selected ? (
            <AddressSummary address={this.props.selected} />
          ) : null}
        </PaneTitle>

        {this.props.active ? (
          <div>
            <CheckoutErrors
              errors={this.props.errors}
              dismiss={this.props.dismissErrors}
            />
            <p>
              {this.props.addresses.length > 0
                ? 'Selecione um endereço existente ou clique em "adicionar novo endereço" e "Salvar". Depois, clique "confirmar entrega".'
                : 'Preencha o endereço, salve e depois clique "confirmar endereço".'}
            </p>

            <AddressAddNewButton
              onClick={this.newAddress.bind(this)}
              isEditing={this.state.editing === 'new'}
            />
            {this.state.editing === 'new' && (
              <AddressEditor
                active={this.state.editing}
                address={this.state.address}
                loading={this.props.addingLoading}
                onSubmitAddress={this.props.onNew}
              />
            )}

            <AddressPicker
              addresses={this.props.addresses}
              selected={this.state.address}
              loading={this.props.loading}
              editing={this.state.editing}
              pickAddress={this.pickAddress.bind(this)}
              updateAddress={this.updateAddress.bind(this)}
              editAddress={this.editAddress.bind(this)}
              deleteAddress={this.deleteAddress.bind(this)}
              editingLoading={this.props.editingLoading}
              deletingLoading={this.props.deletingLoading}
              addressesLoading={this.props.addressesLoading}
            />

            <div className="address-pane-checkout-continue-button">
              <CheckoutContinueButton
                onClick={() => {
                  this.submitAddress();
                  Analytics.logConfirmedAddress();
                }}
                disabled={this.state.address === null}
                text="Confirmar endereço"
                loading={this.props.loading}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default AddressPane;
