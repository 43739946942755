import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import history from './history';
import routes from './routes';
// Analytics
import * as Analytics from './common/analytics';
// Components
import { Router, Switch, Route } from 'react-router-dom';
import { Header, SimpleHeader } from './header';
import { Footer } from './footer';
import { UserValidate } from './user';
import { ScrollToTop, NotFoundPage, Alert } from './common';
import { TopLevelErrorBoundary } from './common';
import WhatsappFloatButton from './WhatsappFloatButton';
// Helpers
import _ from 'lodash';
// Connectors
// Styles
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'instantsearch.css/themes/reset.css';

const App = ({ user }) => {
  useEffect(() => {
    Analytics.setUserProperties(user);
  }, [user]);

  return (
    <TopLevelErrorBoundary>
      <div>
        <Router history={history}>
          <ScrollToTop>
            <div id="app">
              <UserValidate />
              <Alert />
              <WhatsappFloatButton />

              <header>
                <Switch>
                  <Route path="/checkout" component={SimpleHeader} />
                  <Route path="/login" component={SimpleHeader} />
                  <Route path="/cadastro" component={SimpleHeader} />
                  <Route path="/registro" component={SimpleHeader} />
                  <Route path="/esqueci-senha" component={SimpleHeader} />
                  <Route path="/resetar-senha" component={SimpleHeader} />
                  <Route path="/confirmacao" component={SimpleHeader} />
                  <Route component={Header} />
                </Switch>
              </header>
              <main>
                <Switch>
                  {routes.map((route, i) => {
                    const { component, ...options } = route;
                    return <Route key={i} component={component} {...options} />;
                  })}
                  <Route component={NotFoundPage} />
                </Switch>
              </main>
              <footer>
                <Switch>
                  <Route component={Footer} />
                </Switch>
              </footer>
            </div>
          </ScrollToTop>
        </Router>
      </div>
    </TopLevelErrorBoundary>
  );
};

const mapStateToProps = state => {
  return {
    user: {
      id: state.user.information.id || null,
      name: `${state.user.information.firstName} ${state.user.information.lastName}`,
      email: state.user.information.email || '',
      isBusiness: !!_.find(state.user.addresses, a => a.cnpj && a.cnpj !== ''),
      createdAt: state.user.information.createdAt || '',
    },
  };
};

const connector: Connector<{}, Props> = connect(mapStateToProps);

export default connector(App);
